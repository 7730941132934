	<template>
		<div v-if="values">
			<v-btn @click="handleExport" color="secondary">
				Exportar a EXCEL
			</v-btn>
			<!--v-btn @click="handleExportTest" v-if="debug">
				EXCEL test
			</v-btn-->
			<!--div v-if="values" v-show="debug">
				<table
					v-for="week of weeks"
					:key="week.id"
					:id="week.id"
					class="table2excel"
				>
					<template
						v-for="(meal, index) of meals"
					>
							<tr>
								<th>{{ meal.name }}</th>
							</tr>
							<tr>
								<th data-style="Header"></th>
								<th 
									data-style="Header"
										dataType="String"
									v-for="(day, index) of weekDay"
									:key="`day${day}`"
								>
									{{day}}
								</th>
							</tr>
							<template
								v-for="(row, iRow) of rows"
							>
								<template
									v-for="(option, iOption) of row.options"
								>
								<tr>
									<td
										:data-style="`row${row.id}`"
										dataType="String"
									>
										{{ iOption === 0 ? row.name : ' '}}
									</td>
									<td 
										v-for="(day, iDay) of weekDay"
										:key="`day${day}`"
										:data-style="`row${row.id}`"
										dataType="String"
									>
									{{option.name ? option.name.concat('\n') : ''}} {{getValue(week.id, meal.id, row.id, option.id, iDay)}}
									</td>
								</tr>
								</template>
							</template>
					</template>
				</table-->
	<!--table id="tbl1" class="table2excel">
	<thead>
	<tr>

	<th data-style="Header">Product test</th>
	<th data-style="Header">Price</th>
	<th data-style="Header">Available</th>
	<th data-style="Header">Count</th>
	</tr>
	</thead>
	<tbody>
	<tr>
	<td data-style="Changed">Bred</td>
	<td data-style="Missed">1
	</td>
	<td>037.57834600204239857
	</td>
	<td data-style="Header">3
	</td>
	</tr>
	<tr>
	<td>Butter</td>
	<td>4
	</td>
	<td>5
	</td>
	<td >6
	</td>
	</tr>
	</tbody>
	</table>
	<hr>

	<table id="tbl2" class="table2excel">
	<thead>
	<tr>
	<th>Product</th>
	<th>Price</th>
	<th>Available</th>
	<th>Count</th>
	</tr>
	</thead>
	<tbody>
	<tr>
	<td>Bred</td>
	<td>7.5
	</td>
	<td>8
	</td>
	<td>9
	</td>
	</tr>
	<tr>
	<td>Butter</td>
	<td>14
	</td>
	<td>15
	</td>
	<td >16
	</td>
	</tr>
	</tbody>
	</table>

	<table id="joined" class="table2excel">
	<thead>
	<tr></tr>
	</thead>
	<tbody>
	</tbody>
	</table>
		</div-->
  </div>
</template>
<script>
import * as XLSX from "xlsx"
import api from '@/services/api'
import utils from '@/services/utils'
export default {
	props: {
		contentTypeDataID: {
			type: String,
			required: true,
		},
    weeks: {
      type: Array,
      required: true,
    },
    meals: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
	},
	data: () => ({
		values: null,
	}),
	computed: {
		debug () {
      if (process.env.VUE_APP_ENV === 'development') return true
			return this.$route.query.debug && this.$route.query.debug.length > 0
		},
    weekDay () {
      return [
        'Lunes',
        'Martes',
        'Mi�rcoles',
        'Jueves',
        'Viernes',
        'S�bado',
        'Domingo',
      ]
    },
		customStyle () {
			let result = ''
			for (const row of this.rows){
				const color = row.color ? row.color : '#FFFFFF'
				result += `
										<Style ss:ID="row${row.id}">
              				<Alignment ss:Vertical="Top" />
											<Interior ss:Color="${color}" ss:Pattern="Solid"></Interior>
              				<Borders>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Right"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Left"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Top"/>
												<Border ss:Color="${color}" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Bottom"/>
              				</Borders>
											<Font ss:Color="${utils.getOppositeColor(color)}"></Font>
              			</Style>
									`
			}
			return result
		},
	},
  watch: {
    contentTypeDataID () { 
		  this.handleGetData()
    },
  },
	mounted () {
		this.handleGetData()
	},
  methods: {
		prepareData (week) {
			const result = []
			for (const meal of this.meals) 
        for (const [iRow, row] of this.rows.entries()) {
          if (row.options.length > 0) {
            for (const [iOption, option] of row.options.entries()) {
              const aux = {}
              aux['Comidas'] = iRow === 0 && iOption === 0 ? meal.name : ''
              aux['Platos'] = iOption === 0 ? row.name : ''
              for (const [iDay, day] of this.weekDay.entries()) {
                aux[day] = `${option.name ? option.name.concat('\n') : ''} ${this.getValue(week.id, meal.id, row.id, option.id, iDay)}`
              }
              result.push(aux)
            }
          } else {
            // no options
            let iOption = 0
            const aux = {}
            aux['Comidas'] = iRow === 0 && iOption === 0 ? meal.name : ''
            aux['Platos'] = iOption === 0 ? row.name : ''
            for (const [iDay, day] of this.weekDay.entries()) {
              aux[day] = ` ${this.getValue(week.id, meal.id, row.id, null, iDay)}`
            }
            result.push(aux)
          }
        }

			return result
		},
		getValue (weekID, mealID, rowID, optionID, day) {
			const aux = this.values.filter(x => 
                  x.Week === weekID &&
                  x.Row === rowID &&
                  x.Meal === mealID &&
                  x.Day === day.toString() &&
                  (!optionID || x.Option === optionID)
                ).map(item => item.Value).shift()
      return aux ? aux : ''
		},
		handleExport () {
			/*const data = [{
				 firstName: 'John',
				 lastName: 'Doe'
				}, {
				 firstName: 'Smith',
				 lastName: 'Peters'
				}, {
				 firstName: 'Alice',
				 lastName: 'Lee'
				}]
*/
			const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
			/*
			const workSheet = XLSX.utils.aoa_to_sheet(data);
  // Generate a Work Book containing the above sheet.
  const workBook = {
    Sheets: { data: workSheet, cols: [] },
    SheetNames: ["data", 'data2'],
  };
	*/

			const wb = XLSX.utils.book_new()

			for (const week of this.weeks) {
				this.prepareData(week)
				const ws = XLSX.utils.json_to_sheet(this.prepareData(week))
				XLSX.utils.book_append_sheet(wb, ws, week.name.replace(' (actual)', ''))
			}

			const excelBuffer = XLSX.write(wb, { bookType: "xls", type: "array" });
			//const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" });

  // Exporting the file with the desired name and extension.
  //const excelBuffer = XLSX.write(workBook, { bookType: "xlsx", type: "array" });


			const fileData = new Blob([excelBuffer], { type: fileType });
			var link = document.createElement("A")
			link.href = URL.createObjectURL(fileData)
			link.download = 'export.xls'
			//link.download = 'export.csv'
			link.target = '_blank'
			document.body.appendChild(link)
			link.click()
			document.body.removeChild(link)
			
			/*
			const items = [
          { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
          { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
          { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
          { age: 38, first_name: 'Jami', last_name: 'Carney' }
        ]
      console.log(XLSX)
			const data = XLSX.utils.json_to_sheet(this.items)
			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, data, 'data')
			XLSX.writeFile(wb,'demo.xlsx')
	*/
		},
    handleGetData () {
      api.getAllWithoutLimit ('dviewcontent', `v1/private/weekly-menu/${this.contentTypeDataID}`)
        .then((response) => {   
					this.values = response
        })
    },
    /*
    handleExport__ () {
			const tables = this.weeks.map(item => item.id)
			const wsnames = this.weeks.map(item => item.name.replace('(actual)', ''))
      this.tablesToExcel(tables, wsnames, 'export.xls', 'Excel')
    },
    tablesToExcel (tables, wsnames, wbname, appname) {
    	//https://learn.microsoft.com/en-us/javascript/api/excel/excel.style?view=excel-js-preview#excel-excel-style-autoindent-member
      var uri = 'data:application/vnd.ms-excel;base64,'
        , tmplWorkbookXML = `<?xml version="1.0" encoding="UTF-8"?><?mso-application progid="Excel.Sheet"?>
              	<Workbook xmlns="urn:schemas-microsoft-com:office:spreadsheet" xmlns:ss="urn:schemas-microsoft-com:office:spreadsheet" xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel"  xmlns:html="http://www.w3.org/TR/REC-html40">
              	<DocumentProperties xmlns="urn:schemas-microsoft-com:office:office">
              			<Author>Contentmanager</Author>
              			<Created>{created}</Created>
              		</DocumentProperties>
              		<Styles>
              			<Style ss:ID="Default" ss:Name="Normal">
              				<NumberFormat ss:Format=""/>
              			</Style>
              			<Style ss:ID="Header">
              				<Borders>
              					<Border ss:Color="#2B5353" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Right"/>
              					<Border ss:Color="#2B5353" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Left"/>
              					<Border ss:Color="#2B5353" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Top"/>
              					<Border ss:Color="#2B5353" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Bottom"/>
              				</Borders>
              				<Alignment ss:Vertical="Bottom" ss:Horizontal="Center"/>
              				<Font ss:FontName="Calibri" ss:Size="12" ss:Color="#FFFFFF"/>
              				<Interior ss:Color="#2B5353" ss:Pattern="Solid" />
              				<NumberFormat/>
              				<Protection/>
              			</Style>
              			<Style ss:ID="HeaderOLD">
              				<Alignment ss:Vertical="Bottom" ss:Horizontal="Center"/>
              				<Borders>
              					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Right"/>
              					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Left"/>
              					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Top"/>
              					<Border ss:Color="#000000" ss:Weight="2" ss:LineStyle="Continuous" ss:Position="Bottom"/>
              				</Borders>
              				<Font ss:FontName="Calibri" ss:Size="12" ss:Color="#2B5353"/>
              				<Interior ss:Color="#FFFFFF" ss:Pattern="Solid" />
              				<NumberFormat/>
              				<Protection/>
              			</Style>
              			<Style ss:ID="Changed">
              				<Borders/>
              				<Font ss:Color="#ff0000"></Font>
              				<Interior ss:Color="#99CC00" ss:Pattern="Solid"></Interior>
              				<NumberFormat/>
              				<Protection/>
              			</Style>
              			<Style ss:ID="Missed">
              				<Borders/>
              				<Font ss:Color="#ff0000"></Font>
              				<Interior ss:Color="#ff0000" ss:Pattern="Solid"></Interior>
              				<NumberFormat/>
              				<Protection/>
              			</Style>
										${this.customStyle}
              			<Style ss:ID="Test">
              				<Interior ss:Color="#ff1200" ss:Pattern="Solid"></Interior>
              				<NumberFormat ss:Format=""/>
              			</Style>
              			<Style ss:ID="Decimals">
										<NumberFormat ss:Format="Fixed"/>
              			</Style>
              	</Styles>
              	{worksheets}
              </Workbook>`
              , tmplWorksheetXML = `<Worksheet ss:Name="{nameWS}">
              	<ss:Table>
              		{rows}
              	</ss:Table>
              </Worksheet>`
              , tmplCellXML = `			<ss:Cell{attributeStyleID}{attributeFormula}>
              				<ss:Data ss:Type="{nameType}">{data}</ss:Data>
              			</ss:Cell>`
    , base64 = function(s) { return window.btoa(unescape(encodeURIComponent(s))) }
    , format = function(s, c) { return s.replace(/{(\w+)}/g, function(m, p) { return c[p]; }) }

        var ctx = ""
        var workbookXML = ""
        var worksheetsXML = ""
        var rowsXML = ""

        for (var i = 0; i < tables.length; i++) {
          if (!tables[i].nodeType) tables[i] = document.getElementById(tables[i])
          for (var j = 0; j < tables[i].rows.length; j++) {
            rowsXML += '		<ss:Row>'
            for (var k = 0; k < tables[i].rows[j].cells.length; k++) {
              var dataType = tables[i].rows[j].cells[k].getAttribute("data-type")
              var dataStyle = tables[i].rows[j].cells[k].getAttribute("data-style")
              var dataValue = tables[i].rows[j].cells[k].getAttribute("data-value")
              dataValue = (dataValue)?dataValue:tables[i].rows[j].cells[k].innerHTML
              if(!isNaN(dataValue)){
                  dataType = 'String'
                  //dataType = 'Number'
                  //dataValue = parseFloat(dataValue)
              }
              var dataFormula = tables[i].rows[j].cells[k].getAttribute("data-formula")
              dataFormula = (dataFormula)?dataFormula:(appname=='Calc' && dataType=='DateTime')?dataValue:null

							const dataStyles = ['Changed', 'Missed', 'Header', 'Test']
							dataStyles.push(...this.rows.map(item => `row${item.id}`))
							
							ctx = {  attributeStyleID: (dataStyles.filter(x => x == dataStyle).length >= 0)?' ss:StyleID="'+dataStyle+'"':''
                     , nameType: (dataType=='Number' || dataType=='DateTime' || dataType=='Boolean' || dataType=='Error')?dataType:'String'
                     , data: (dataFormula)?'':dataValue
                     , attributeFormula: (dataFormula)?' ss:Formula="'+dataFormula+'"':''
                    }
              rowsXML += format(tmplCellXML, ctx)
            }
            rowsXML += '		</ss:Row>'
          }
          ctx = {rows: rowsXML, nameWS: wsnames[i] || 'Sheet' + i}
          worksheetsXML += format(tmplWorksheetXML, ctx)
          rowsXML = ""
        }

        ctx = {created: (new Date()).getTime(), worksheets: worksheetsXML}
        workbookXML = format(tmplWorkbookXML, ctx)
        var link = document.createElement("A")
        link.href = uri + base64(workbookXML)
        link.download = wbname || 'Workbook.xls'
        link.target = '_blank'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    },*/
  },
}
</script>

